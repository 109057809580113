import { Component, OnInit, Input } from '@angular/core';
import { AuthService } from '../../../common/services/auth.service';
import { Router, RouterStateSnapshot } from '@angular/router';
import { first, timeout } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { HeaderService } from '@src/app/common/services/header.service';
import { constants } from '@src/app/common/constants/app.constants';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  blogEvent: any;
  logo: string;
  jsonUser: any;
  nameUser: string;
  nameSupplier: string;
  menuContent: object;

  // tslint:disable-next-line:no-input-rename
  @Input('b2sContent')
  public b2sContent: any;

  // tslint:disable-next-line:variable-name
  constructor(private _authService: AuthService, private _router: Router, private _route: ActivatedRoute, private headerService: HeaderService,) {
    this._route.params
      .subscribe(params => {

        const json = {
          data: 20,
        };
        this.getLoginService(json);

      });
    this.getMenuContent()

  }

  ngOnInit() {
    // this.logo = localStorage.getItem('logo');
    this.jsonUser = JSON.parse(localStorage.getItem('userData'));
    console.log(this.jsonUser);
    this.nameUser = this.jsonUser.contactFirstName;
    this.nameSupplier = this.jsonUser.razon_social;
    console.log("🚀 ~ HeaderComponent ~ ngOnInit ~ this.jsonUser:", this.jsonUser)
    console.log("🚀 ~ HeaderComponent ~ ngOnInit ~ this.jsonUser.razon_social:", this.jsonUser.razon_social)
    this.menuContent = {}

  }

  logout() {
    this._authService.logoutService();
    localStorage.removeItem('authenticate');
    localStorage.removeItem('session_token');
    localStorage.removeItem('userData');
    localStorage.removeItem('descarga_certificado');
    this._router.navigate(['#/login']);
  }

  getLoginService(params) {
    this._authService.getDataLogin(params).pipe(first(), timeout(1000)).subscribe(
      (response: any) => {
        if (response.error) {
          this.logo = localStorage.getItem('logo');
        } else {
          this.logo = response.logo;
        }
      }, (error: any) => {
        this.logo = localStorage.getItem('logo');
      }
    );
  }

  sortByOrder(a: any, b: any) {
    return a.value.order - b.value.order;
  }

  existContent(obj: any): boolean {
    return obj !== null && Object.keys(obj).length > 0;
  }

  getMenuContent() {
    this.headerService.getMenuData().subscribe(
      (response: any) => {
        this.menuContent = response.menuContent

        // Subitems sorted
        Object.keys(this.menuContent).forEach((key) => {
          let valorActual = this.menuContent[key]["data"];
          valorActual.sort((a: any, b: any) => a.order - b.order);
          this.menuContent[key]["data"] = valorActual;
        })
      },
      (error: any) => {
        console.error(error);
      }
    );
  }

  redirectToExternalURL(itemObj) {
    let externalURL = itemObj.data.filter(item => item.type == "ext")[0].value
    window.open(externalURL, '_blank');
  }

}
