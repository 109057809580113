export const environment = {
  production: false,

  clientName: 'DIABONOS - AGP',
  apiGatewayFront: 'https://adminagp.diabonos.com/',
  apiGatewayBackOffice: 'https://adminagp.diabonos.com/',
  apiUrl: 'https://adminagp.diabonos.com/',
  urlPath: 'https://adminagp.diabonos.com/',
  customCss: false,
  applicationType: "agp",
  // Whether or not to enable debug mode
  // Setting this to false will disable console output
  enableDebug: false,
  // siteKeyCaptcha: '6LdLtBsgAAAAAJksLT4W-TI-oRed7Uk9B8Y-EECB', DEMO
  // siteKeyCaptcha: '6LfL5fkfAAAAALmrMTjEg18wnQm5uR3ZNbBy-pL7' ZX

  siteKeyCaptcha: '6LfI2IMpAAAAAABxm7MVUOaq_qw8ytXpNF3Xbl2c',

  googleAnalyticsCode: 'UA-140785247-1',
};


const environment2 = {
  production: false,
  clientName: 'DIABONOS - AGP',
  apiGatewayFront: 'https://adminagp.diabonos.com/',
  apiGatewayBackOffice: 'https://adminagp.diabonos.com/',
  apiUrl: 'https://adminagp.diabonos.com/',
  urlPath: 'https://adminagp.diabonos.com/',
  customCss: false,
  // Whether or not to enable debug mode
  // Setting this to false will disable console output
  enableDebug: false,
  // siteKeyCaptcha: '6LdLtBsgAAAAAJksLT4W-TI-oRed7Uk9B8Y-EECB', DEMO
  // siteKeyCaptcha: '6LfL5fkfAAAAALmrMTjEg18wnQm5uR3ZNbBy-pL7' ZX
  siteKeyCaptcha: '6LfI2IMpAAAAAABxm7MVUOaq_qw8ytXpNF3Xbl2c',
  googleAnalyticsCode: 'UA-140785247-1',
};
